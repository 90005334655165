import http from "../http-common"; 

class ExportService {
  getAllExports(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/export/exports`, searchDTO);
  }

  get(exportId) {
    return this.getRequest(`/export/${exportId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/export?field=${matchData}`, null);
  }

  addExport(data) {
    return http.post("/export/addExport", data);
  }

  update(data) {
  	return http.post("/export/updateExport", data);
  }
  
  uploadImage(data,exportId) {
  	return http.postForm("/export/uploadImage/"+exportId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new ExportService();
