import http from "../http-common"; 

class TournamentService {
  getAllTournaments(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/tournament/tournaments`, searchDTO);
  }

  get(tournamentId) {
    return this.getRequest(`/tournament/${tournamentId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/tournament?field=${matchData}`, null);
  }

  addTournament(data) {
    return http.post("/tournament/addTournament", data);
  }

  update(data) {
  	return http.post("/tournament/updateTournament", data);
  }
  
  uploadImage(data,tournamentId) {
  	return http.postForm("/tournament/uploadImage/"+tournamentId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new TournamentService();
