import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Tournaments from  '@/pages/Tournaments.vue';
import TournamentDetail from  '@/pages/TournamentDetail.vue';
import Formats from  '@/pages/Formats.vue';
import FormatDetail from  '@/pages/FormatDetail.vue';
import Participants from  '@/pages/Participants.vue';
import ParticipantDetail from  '@/pages/ParticipantDetail.vue';
import Matchs from  '@/pages/Matchs.vue';
import MatchDetail from  '@/pages/MatchDetail.vue';
import Brackets from  '@/pages/Brackets.vue';
import BracketDetail from  '@/pages/BracketDetail.vue';
import ScoreUpdates from  '@/pages/ScoreUpdates.vue';
import ScoreUpdateDetail from  '@/pages/ScoreUpdateDetail.vue';
import Standings from  '@/pages/Standings.vue';
import StandingDetail from  '@/pages/StandingDetail.vue';
import Exports from  '@/pages/Exports.vue';
import ExportDetail from  '@/pages/ExportDetail.vue';
import Users from  '@/pages/Users.vue';
import UserDetail from  '@/pages/UserDetail.vue';
import Organizers from  '@/pages/Organizers.vue';
import OrganizerDetail from  '@/pages/OrganizerDetail.vue';
import Venues from  '@/pages/Venues.vue';
import VenueDetail from  '@/pages/VenueDetail.vue';
import MatchSchedules from  '@/pages/MatchSchedules.vue';
import MatchScheduleDetail from  '@/pages/MatchScheduleDetail.vue';
import Notifications from  '@/pages/Notifications.vue';
import NotificationDetail from  '@/pages/NotificationDetail.vue';
import Messages from  '@/pages/Messages.vue';
import MessageDetail from  '@/pages/MessageDetail.vue';
import TournamentDetailss from  '@/pages/TournamentDetailss.vue';
import TournamentDetailsDetail from  '@/pages/TournamentDetailsDetail.vue';
import AutoSchedulers from  '@/pages/AutoSchedulers.vue';
import AutoSchedulerDetail from  '@/pages/AutoSchedulerDetail.vue';
import Reports from  '@/pages/Reports.vue';
import ReportDetail from  '@/pages/ReportDetail.vue';
import ActivityLogs from  '@/pages/ActivityLogs.vue';
import ActivityLogDetail from  '@/pages/ActivityLogDetail.vue';
import Sharings from  '@/pages/Sharings.vue';
import SharingDetail from  '@/pages/SharingDetail.vue';
import Feedbacks from  '@/pages/Feedbacks.vue';
import FeedbackDetail from  '@/pages/FeedbackDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
			redirect: '/tournaments',
																				  },
  {
    path: "/pricing",
    name: "PricingView",
    component: () => import("../views/PricingView.vue"),
  },
  {
    path: "/arts-gallery",
    name: "ArtsGalleryView",
    component: () => import("../views/ArtsGalleryView.vue"),
  },
  {
    path: "/checkout/:id",
    name: "CheckoutView",
    component: () => import("../views/CheckoutView.vue"),
  },
  {
    path: "/stripe-checkout",
    name: "StripeCheckoutView",
    component: () => import("../views/StripeCheckoutView.vue"),
  },
	{
		path: '/tournaments',
		name: 'Tournaments',
		layout: DefaultLayout,
		component: Tournaments,
	},
	{
	    path: '/tournament/:tournamentId', 
	    name: 'TournamentDetail',
		layout: DefaultLayout,
	    component: TournamentDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/formats',
		name: 'Formats',
		layout: DefaultLayout,
		component: Formats,
	},
	{
	    path: '/format/:formatId', 
	    name: 'FormatDetail',
		layout: DefaultLayout,
	    component: FormatDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/participants',
		name: 'Participants',
		layout: DefaultLayout,
		component: Participants,
	},
	{
	    path: '/participant/:participantId', 
	    name: 'ParticipantDetail',
		layout: DefaultLayout,
	    component: ParticipantDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/matchs',
		name: 'Matchs',
		layout: DefaultLayout,
		component: Matchs,
	},
	{
	    path: '/match/:matchId', 
	    name: 'MatchDetail',
		layout: DefaultLayout,
	    component: MatchDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/brackets',
		name: 'Brackets',
		layout: DefaultLayout,
		component: Brackets,
	},
	{
	    path: '/bracket/:bracketId', 
	    name: 'BracketDetail',
		layout: DefaultLayout,
	    component: BracketDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/scoreUpdates',
		name: 'ScoreUpdates',
		layout: DefaultLayout,
		component: ScoreUpdates,
	},
	{
	    path: '/scoreUpdate/:scoreUpdateId', 
	    name: 'ScoreUpdateDetail',
		layout: DefaultLayout,
	    component: ScoreUpdateDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/standings',
		name: 'Standings',
		layout: DefaultLayout,
		component: Standings,
	},
	{
	    path: '/standing/:standingId', 
	    name: 'StandingDetail',
		layout: DefaultLayout,
	    component: StandingDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/exports',
		name: 'Exports',
		layout: DefaultLayout,
		component: Exports,
	},
	{
	    path: '/export/:exportId', 
	    name: 'ExportDetail',
		layout: DefaultLayout,
	    component: ExportDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/users',
		name: 'Users',
		layout: DefaultLayout,
		component: Users,
	},
	{
	    path: '/user/:userId', 
	    name: 'UserDetail',
		layout: DefaultLayout,
	    component: UserDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/organizers',
		name: 'Organizers',
		layout: DefaultLayout,
		component: Organizers,
	},
	{
	    path: '/organizer/:organizerId', 
	    name: 'OrganizerDetail',
		layout: DefaultLayout,
	    component: OrganizerDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/venues',
		name: 'Venues',
		layout: DefaultLayout,
		component: Venues,
	},
	{
	    path: '/venue/:venueId', 
	    name: 'VenueDetail',
		layout: DefaultLayout,
	    component: VenueDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/matchSchedules',
		name: 'MatchSchedules',
		layout: DefaultLayout,
		component: MatchSchedules,
	},
	{
	    path: '/matchSchedule/:matchScheduleId', 
	    name: 'MatchScheduleDetail',
		layout: DefaultLayout,
	    component: MatchScheduleDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/notifications',
		name: 'Notifications',
		layout: DefaultLayout,
		component: Notifications,
	},
	{
	    path: '/notification/:notificationId', 
	    name: 'NotificationDetail',
		layout: DefaultLayout,
	    component: NotificationDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/messages',
		name: 'Messages',
		layout: DefaultLayout,
		component: Messages,
	},
	{
	    path: '/message/:messageId', 
	    name: 'MessageDetail',
		layout: DefaultLayout,
	    component: MessageDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/tournamentDetailss',
		name: 'TournamentDetailss',
		layout: DefaultLayout,
		component: TournamentDetailss,
	},
	{
	    path: '/tournamentDetails/:tournamentDetailsId', 
	    name: 'TournamentDetailsDetail',
		layout: DefaultLayout,
	    component: TournamentDetailsDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/autoSchedulers',
		name: 'AutoSchedulers',
		layout: DefaultLayout,
		component: AutoSchedulers,
	},
	{
	    path: '/autoScheduler/:autoSchedulerId', 
	    name: 'AutoSchedulerDetail',
		layout: DefaultLayout,
	    component: AutoSchedulerDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/reports',
		name: 'Reports',
		layout: DefaultLayout,
		component: Reports,
	},
	{
	    path: '/report/:reportId', 
	    name: 'ReportDetail',
		layout: DefaultLayout,
	    component: ReportDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/activityLogs',
		name: 'ActivityLogs',
		layout: DefaultLayout,
		component: ActivityLogs,
	},
	{
	    path: '/activityLog/:activityLogId', 
	    name: 'ActivityLogDetail',
		layout: DefaultLayout,
	    component: ActivityLogDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/sharings',
		name: 'Sharings',
		layout: DefaultLayout,
		component: Sharings,
	},
	{
	    path: '/sharing/:sharingId', 
	    name: 'SharingDetail',
		layout: DefaultLayout,
	    component: SharingDetail,
	    props: true // Pass route params as props to the component
  	},
	{
		path: '/feedbacks',
		name: 'Feedbacks',
		layout: DefaultLayout,
		component: Feedbacks,
	},
	{
	    path: '/feedback/:feedbackId', 
	    name: 'FeedbackDetail',
		layout: DefaultLayout,
	    component: FeedbackDetail,
	    props: true // Pass route params as props to the component
  	},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
