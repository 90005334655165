import http from "../http-common"; 

class VenueService {
  getAllVenues(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/venue/venues`, searchDTO);
  }

  get(venueId) {
    return this.getRequest(`/venue/${venueId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/venue?field=${matchData}`, null);
  }

  addVenue(data) {
    return http.post("/venue/addVenue", data);
  }

  update(data) {
  	return http.post("/venue/updateVenue", data);
  }
  
  uploadImage(data,venueId) {
  	return http.postForm("/venue/uploadImage/"+venueId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new VenueService();
