import http from "../http-common"; 

class BracketService {
  getAllBrackets(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/bracket/brackets`, searchDTO);
  }

  get(bracketId) {
    return this.getRequest(`/bracket/${bracketId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/bracket?field=${matchData}`, null);
  }

  addBracket(data) {
    return http.post("/bracket/addBracket", data);
  }

  update(data) {
  	return http.post("/bracket/updateBracket", data);
  }
  
  uploadImage(data,bracketId) {
  	return http.postForm("/bracket/uploadImage/"+bracketId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new BracketService();
