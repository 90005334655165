import { render, staticRenderFns } from "./StandingPictureView.vue?vue&type=template&id=11c04bdc&scoped=true"
import script from "./StandingPictureView.vue?vue&type=script&lang=js"
export * from "./StandingPictureView.vue?vue&type=script&lang=js"
import style0 from "./StandingPictureView.vue?vue&type=style&index=0&id=11c04bdc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c04bdc",
  null
  
)

export default component.exports