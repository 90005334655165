import http from "../http-common"; 

class MatchScheduleService {
  getAllMatchSchedules(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/matchSchedule/matchSchedules`, searchDTO);
  }

  get(matchScheduleId) {
    return this.getRequest(`/matchSchedule/${matchScheduleId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/matchSchedule?field=${matchData}`, null);
  }

  addMatchSchedule(data) {
    return http.post("/matchSchedule/addMatchSchedule", data);
  }

  update(data) {
  	return http.post("/matchSchedule/updateMatchSchedule", data);
  }
  
  uploadImage(data,matchScheduleId) {
  	return http.postForm("/matchSchedule/uploadImage/"+matchScheduleId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new MatchScheduleService();
