import http from "../http-common"; 

class AutoSchedulerService {
  getAllAutoSchedulers(searchDTO) {
    console.log(searchDTO)
    return this.getRequest(`/autoScheduler/autoSchedulers`, searchDTO);
  }

  get(autoSchedulerId) {
    return this.getRequest(`/autoScheduler/${autoSchedulerId}`, null);
  }

  findByField(matchData) {
    return this.getRequest(`/autoScheduler?field=${matchData}`, null);
  }

  addAutoScheduler(data) {
    return http.post("/autoScheduler/addAutoScheduler", data);
  }

  update(data) {
  	return http.post("/autoScheduler/updateAutoScheduler", data);
  }
  
  uploadImage(data,autoSchedulerId) {
  	return http.postForm("/autoScheduler/uploadImage/"+autoSchedulerId, data);
  }




	postRequest(url, data) {
		return http.post(url, data);
      };

	getRequest(url, params) {
        return http.get(url, {
        	params: params
        });
    };

}

export default new AutoSchedulerService();
