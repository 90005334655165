
<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3 justify-content-end">
      <div class="col-8"></div>
       <div class="col-auto">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h5 class="card-title">Venues</h5>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalVenues = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalVenues">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Venue</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="Location" type="text" placeholder="Enter Location" v-model="venueToAdd.location"></base-input>
  <base-input label="Name" type="text" placeholder="Enter Name" v-model="venueToAdd.name"></base-input>
  <base-input label="VenueId" type="text" placeholder="Enter VenueId" v-model="venueToAdd.venueId"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="handleAddSubmitted()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="venues" :row-key="record => record.VenueId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">



             <template slot="lastModified" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="createdOn" slot-scope="dataIndex">
              	{{ formatTime(dataIndex) }}
              </template>
              <template slot="blackOutStartDate" slot-scope="dataIndex">
              	{{ formatDate(dataIndex) }}
              </template>
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <VenuePictureView :venues="venues" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/Card";
import VenueService from "../services/VenueService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import VenuePictureView from './VenuePictureView.vue';


const venuesColumns = [
  "venueId",
  "year",
  "date",
  "competitionId",
  "venueId"
]

export default {
  props: {
    venues: {
      type: Array,
      required: true,
    },
    totalElements: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    VenuePictureView
  },

  data() {
    return {
      modalVenues: false,
        isTableView: true,

      columns: [
        {
          title: 'Venue Id',
		dataIndex: 'venueId',
          visible: true,
          scopedSlots: { customRender: 'venueId' },
          sorter: true
          //sorter: (a, b) => a.venueId - b.venueId,
          //sorter: (a, b) => a.venueId.localeCompare(b.venueId),
        },
        {
          title: 'Name',
		dataIndex: 'name',
          visible: true,
          scopedSlots: { customRender: 'name' },
          sorter: true
          //sorter: (a, b) => a.name - b.name,
          //sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
          title: 'Location',
		dataIndex: 'location',
          visible: true,
          scopedSlots: { customRender: 'location' },
          sorter: true
          //sorter: (a, b) => a.location - b.location,
          //sorter: (a, b) => a.location.localeCompare(b.location),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} venues`,
      },

      venues: [],
      venueToAdd: {},

      venuesTable: {
        columns: [...venuesColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'venueId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "handleSearchQueryChanged", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async renderVenuesTable() {
      this.searchLoading = true; // Set searchLoading to true while fetching data
      this.searchLoading = false;

      this.pagination.total = this.totalElements;
      this.pagination.current = this.page;

      let venuesTableData = [];
      for (let i = 0; i < this.venues.length; i++) {
        venuesTableData.push({
          id: i,
          venueId: this.venues[i].venueId,
          year: this.venues[i].year,
          date: this.venues[i].date,
          competitionId: this.venues[i].competitionId,
          venueId: this.venues[i].venueId,
        });

      }
      this.searchLoading = false;
    },

    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

	  this.$emit('get-all-venues',this.sortBy,this.sortOrder,this.pagination.current-1,this.pagination.pageSize);
      this.handleTableChanged()
    },
	
	initializeColumns() {
        this.columns = this.columns.filter(item => item.visible);
    },

    routingToFeedbackDetail(id) {
      this.$router.push({ name: 'FeedbackDetail', params: { feedbackId: id.toString() }})
    },
    routingToAutoSchedulerDetail(id) {
      this.$router.push({ name: 'AutoSchedulerDetail', params: { autoSchedulerId: id.toString() }})
    },
    routingToMessageDetail(id) {
      this.$router.push({ name: 'MessageDetail', params: { messageId: id.toString() }})
    },
    routingToExportDetail(id) {
      this.$router.push({ name: 'ExportDetail', params: { exportId: id.toString() }})
    },
    routingToScoreUpdateDetail(id) {
      this.$router.push({ name: 'ScoreUpdateDetail', params: { scoreUpdateId: id.toString() }})
    },
    routingToMatchDetail(id) {
      this.$router.push({ name: 'MatchDetail', params: { matchId: id.toString() }})
    },
    routingToFormatDetail(id) {
      this.$router.push({ name: 'FormatDetail', params: { formatId: id.toString() }})
    },
    routingToActivityLogDetail(id) {
      this.$router.push({ name: 'ActivityLogDetail', params: { activityLogId: id.toString() }})
    },
    routingToMatchScheduleDetail(id) {
      this.$router.push({ name: 'MatchScheduleDetail', params: { matchScheduleId: id.toString() }})
    },
    routingToOrganizerDetail(id) {
      this.$router.push({ name: 'OrganizerDetail', params: { organizerId: id.toString() }})
    },
    routingToSharingDetail(id) {
      this.$router.push({ name: 'SharingDetail', params: { sharingId: id.toString() }})
    },
    routingToReportDetail(id) {
      this.$router.push({ name: 'ReportDetail', params: { reportId: id.toString() }})
    },
    routingToTournamentDetailsDetail(id) {
      this.$router.push({ name: 'TournamentDetailsDetail', params: { tournamentDetailsId: id.toString() }})
    },
    routingToNotificationDetail(id) {
      this.$router.push({ name: 'NotificationDetail', params: { notificationId: id.toString() }})
    },
    routingToVenueDetail(id) {
      this.$router.push({ name: 'VenueDetail', params: { venueId: id.toString() }})
    },
    routingToUserDetail(id) {
      this.$router.push({ name: 'UserDetail', params: { userId: id.toString() }})
    },
    routingToStandingDetail(id) {
      this.$router.push({ name: 'StandingDetail', params: { standingId: id.toString() }})
    },
    routingToBracketDetail(id) {
      this.$router.push({ name: 'BracketDetail', params: { bracketId: id.toString() }})
    },
    routingToParticipantDetail(id) {
      this.$router.push({ name: 'ParticipantDetail', params: { participantId: id.toString() }})
    },
    routingToTournamentDetail(id) {
      this.$router.push({ name: 'TournamentDetail', params: { tournamentId: id.toString() }})
    },
    
    handleSearchQueryChanged() {
    	console.log("handleSearchQueryChanged CALLED FROM @search")
    	this.$root.$emit('searchQueryForVenuesChanged', this.searchQuery);
		//this.renderVenuesTable();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
	
	async handleAddSubmitted() {
      this.modalVenues = false;

      const currentDate = new Date().getTime();
      this.venueToAdd.created = currentDate;

      const jsonData = JSON.stringify(this.venueToAdd);
      console.log(jsonData);
      
      const res = await VenueService.addVenue(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},
	
	handleTableChanged() {
	},
	
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
  	this.initializeColumns();
    this.renderVenuesTable();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
.ant-table-row  {
	text-align: center;
}
.ant-table-thead th span {
	text-align: center;
	width: 100%
}
.ant-table-fixed td span {
    text-align: center;
}
.header-search {
  width: 300px !important;
  margin-left: auto !important;
}
</style>
